import Localbase from 'localbase'
import {isNumber} from "lodash/lang";
const EventEmitter = require('events');
class MyEmitter extends EventEmitter {}
let db = new Localbase('nvlss');
//let dbQ = new Localbase('workbox-background-sync');
db.config.debug = false;
const serverURL = 'https://drivers.nvlss.com';
const readDelay = 500;
const ordersDbName = "orders";
const completedOrdersDbName = "completedOrders";
const queuedOrdersDbName = "orderQue";
const settingsDbName = "settings";
const userDbName = "user";
const newPhotosDbName = "newPhotos";
const capturedPhotosDbName = "capturedPhotos";
const getNewOrdersAPIUrl = "/driver/action/getDriversNewOrders?utm_driver_id=";
const getCompletedOrdersAPIUrl = "/driver/action/getDriversCompletedOrders?utm_driver_id=";
const getUploadBulkImagesUrl = "/order/photos/add"
const getCapturedPhotoUploadUrl = '/order/photo/add'
const getSettingsAPIUrl = "/driver/action/getSettings";
const getNewUserAPIUrl = "/driver/action/getUserData";
const checkConnectivityAPIUrl = "/driver/action/checkConnectivity";
const updateRemoteOrderAPIUrl = "/order/action/updateOrder";
const updateRemoteBulkOrdersAPIUrl = "/order/action/updateOrderBulk";
//let isLocalDbFinishedWithSync = false;
window.currentUserName = "";
window.currentUserID = "";
window.NewOrderData = [];
window.OrderQue = [];
window.photos = [];
window.capturedPhotos = [];
window.CurrentOrder = [];
window.lastOrderToMoveToCompletedFromQue = "";
//:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
//:::                                                                         :::
//:::  This routine calculates the distance between two points (given the     :::
//:::  latitude/longitude of those points). It is being used to calculate     :::
//:::  the distance between two locations using GeoDataSource (TM) prodducts  :::
//:::                                                                         :::
//:::  Definitions:                                                           :::
//:::    South latitudes are negative, east longitudes are positive           :::
//:::                                                                         :::
//:::  Passed to function:                                                    :::
//:::    lat1, lon1 = Latitude and Longitude of point 1 (in decimal degrees)  :::
//:::    lat2, lon2 = Latitude and Longitude of point 2 (in decimal degrees)  :::
//:::    unit = the unit you desire for results                               :::
//:::           where: 'M' is statute miles (default)                         :::
//:::                  'K' is kilometers                                      :::
//:::                  'N' is nautical miles                                  :::
//:::                                                                         :::
//:::  Worldwide cities and other features databases with latitude longitude  :::
//:::  are available at https://www.geodatasource.com                         :::
//:::                                                                         :::
//:::  For enquiries, please contact sales@geodatasource.com                  :::
//:::                                                                         :::
//:::  Official Web site: https://www.geodatasource.com                       :::
//:::                                                                         :::
//:::               GeoDataSource.com (C) All Rights Reserved 2018            :::
//:::                                                                         :::
//:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
window.distance = function (lat1, lon1, lat2, lon2, unit) {
    if ((lat1 === lat2) && (lon1 === lon2)) {
        return 0;
    } else {
        var radlat1 = Math.PI * lat1 / 180;
        var radlat2 = Math.PI * lat2 / 180;
        var theta = lon1 - lon2;
        var radtheta = Math.PI * theta / 180;
        var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
        if (dist > 1) {
            dist = 1;
        }
        dist = Math.acos(dist);
        dist = dist * 180 / Math.PI;
        dist = dist * 60 * 1.1515;
        if (unit === "K") {
            dist = dist * 1.609344
        }
        if (unit === "N") {
            dist = dist * 0.8684
        }
        return dist;
    }
}
window.isLocalDBSupported = function () {
    window.indexedDB = window.indexedDB || window.mozIndexedDB || window.webkitIndexedDB || window.msIndexedDB;
    window.IDBTransaction = window.IDBTransaction || window.webkitIDBTransaction || window.msIDBTransaction || {READ_WRITE: "readwrite"}; // This line should only be needed if it is needed to support the object's constants for older browsers
    window.IDBKeyRange = window.IDBKeyRange || window.webkitIDBKeyRange || window.msIDBKeyRange;
    return window.indexedDB;
}
window.isReachable = function (url) {
    /**
     * Note: fetch() still "succeeds" for 404s on subdirectories,
     * which is ok when only testing for domain reachability.
     *
     * Example:
     *   https://google.com/noexist does not throw
     *   https://noexist.com/noexist does throw
     */
    return fetch(url, {method: 'HEAD', mode: 'no-cors'})
        .then(function (resp) {
            return resp && (resp.ok || resp.type === 'opaque');
        })
        .catch(function (err) {
            console.warn('[conn test failure]:', err);
        });
}
window.getCacheStoragesAssetTotalSize = async function () {
    // Note: opaque (i.e. cross-domain, without CORS) responses in the cache will return a size of 0.
    const cacheNames = await caches.keys();
    let total = 0;

    const sizePromises = cacheNames.map(async cacheName => {
        const cache = await caches.open(cacheName);
        const keys = await cache.keys();
        let cacheSize = 0;

        await Promise.all(keys.map(async key => {
            const response = await cache.match(key);
            const blob = await response.blob();
            total += blob.size;
            cacheSize += blob.size;
        }));

        console.log(`Cache ${cacheName}: ${cacheSize} bytes`);
    });

    await Promise.all(sizePromises);
    return window.formatBytes(total);
}
window.formatBytes = function (bytes, decimals = 2) {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}
window.getParameterByName = function (name, url = window.location.href) {
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'), results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
}
//return an array of objects according to key, value, or key and value matching
window.getObjects = function (obj, key, val) {
    var objects = [];
    for (var i in obj) {
        if (!obj.hasOwnProperty(i)) continue;
        if (typeof obj[i] == 'object') {
            objects = objects.concat(getObjects(obj[i], key, val));
        } else
            //if key matches and value matches or if key matches and value is not passed (eliminating the case where key matches but passed value does not)
        if (i == key && obj[i] == val || i == key && val == '') { //
            objects.push(obj);
        } else if (obj[i] == val && key == '') {
            //only add if the object is not already in the array
            if (objects.lastIndexOf(obj) == -1) {
                objects.push(obj);
            }
        }
    }
    return objects;
}
//return an array of values that match on a certain key
window.getValues = function (obj, key) {
    var objects = [];
    for (var i in obj) {
        if (!obj.hasOwnProperty(i)) continue;
        if (typeof obj[i] == 'object') {
            objects = objects.concat(getValues(obj[i], key));
        } else if (i == key) {
            objects.push(obj[i]);
        }
    }
    return objects;
}
//return an array of keys that match on a certain value
window.getKeys = function (obj, val) {
    var objects = [];
    for (var i in obj) {
        if (!obj.hasOwnProperty(i)) continue;
        if (typeof obj[i] == 'object') {
            objects = objects.concat(getKeys(obj[i], val));
        } else if (obj[i] == val) {
            objects.push(i);
        }
    }
    return objects;
}
window.addLog = (logEntry) => {
    let statusWindow = $('#appLoader');
    statusWindow.html(statusWindow.html() + "<br>" + logEntry)
}
window.getServerUrl = () => {
    return serverURL;
}
window.GetHtmlToDisplayDeviceInfo = () => {
    'use strict';
    let module = {
        options: [],
        header: [navigator.platform, navigator.userAgent, navigator.appVersion, navigator.vendor, window.opera],
        dataos: [{name: 'Windows Phone', value: 'Windows Phone', version: 'OS'}, {name: 'Windows', value: 'Win', version: 'NT'}, {
            name: 'iPhone',
            value: 'iPhone',
            version: 'OS'
        }, {name: 'iPad', value: 'iPad', version: 'OS'}, {name: 'Kindle', value: 'Silk', version: 'Silk'}, {
            name: 'Android',
            value: 'Android',
            version: 'Android'
        }, {name: 'PlayBook', value: 'PlayBook', version: 'OS'}, {name: 'BlackBerry', value: 'BlackBerry', version: '/'}, {
            name: 'Macintosh',
            value: 'Mac',
            version: 'OS X'
        }, {name: 'Linux', value: 'Linux', version: 'rv'}, {name: 'Palm', value: 'Palm', version: 'PalmOS'}],
        databrowser: [{name: 'Chrome', value: 'Chrome', version: 'Chrome'}, {name: 'Firefox', value: 'Firefox', version: 'Firefox'}, {
            name: 'Safari',
            value: 'Safari',
            version: 'Version'
        }, {name: 'Internet Explorer', value: 'MSIE', version: 'MSIE'}, {name: 'Opera', value: 'Opera', version: 'Opera'}, {
            name: 'BlackBerry',
            value: 'CLDC',
            version: 'CLDC'
        }, {name: 'Mozilla', value: 'Mozilla', version: 'Mozilla'}],
        init: function () {
            var agent = this.header.join(' '), os = this.matchItem(agent, this.dataos), browser = this.matchItem(agent, this.databrowser);

            return {os: os, browser: browser};
        },
        matchItem: function (string, data) {
            var i = 0, j = 0, html = '', regex, regexv, match, matches, version;

            for (i = 0; i < data.length; i += 1) {
                regex = new RegExp(data[i].value, 'i');
                match = regex.test(string);
                if (match) {
                    regexv = new RegExp(data[i].version + '[- /:;]([\\d._]+)', 'i');
                    matches = string.match(regexv);
                    version = '';
                    if (matches) {
                        if (matches[1]) {
                            matches = matches[1];
                        }
                    }
                    if (matches) {
                        matches = matches.split(/[._]+/);
                        for (j = 0; j < matches.length; j += 1) {
                            if (j === 0) {
                                version += matches[j] + '.';
                            } else {
                                version += matches[j];
                            }
                        }
                    } else {
                        version = '0';
                    }
                    return {
                        name: data[i].name, version: parseFloat(version)
                    };
                }
            }
            return {name: 'unknown', version: 0};
        }
    };
    let e = module.init(), debug = '';
    debug += 'os.name = ' + e.os.name + '<br/>';
    debug += 'os.version = ' + e.os.version + '<br/>';
    debug += 'browser.name = ' + e.browser.name + '<br/>';
    debug += 'browser.version = ' + e.browser.version + '<br/>';

    debug += '<br/>';
    debug += 'navigator.userAgent = ' + navigator.userAgent + '<br/>';
    debug += 'navigator.appVersion = ' + navigator.appVersion + '<br/>';
    debug += 'navigator.platform = ' + navigator.platform + '<br/>';
    debug += 'navigator.vendor = ' + navigator.vendor + '<hr/>';
    return (debug)
}
window.myEmitter = new MyEmitter();
/**
 * Event Listener For finishedPopulatingLocalOrdersDB
 * Retrieves new orders and sets the window.Neworders global array
 *
 */
myEmitter.on('finishedPopulatingLocalOrdersDB', () => {
    db.collection(ordersDbName).get().then(orders => {
        window.Neworders = orders;
        myEmitter.emit('GetLocalNewOrdersDBReadyEvent');
    })
});
/**
 * Event Listener For finishedPopulatingCompletedOrdersDB
 * Retrieves completed orders and sets the window.Completedorders global array
 *
 */
myEmitter.on('finishedPopulatingCompletedOrdersDB', () => {
    db.collection(completedOrdersDbName).get().then(orders => {
        window.Completedorders = orders;
        myEmitter.emit('GetLocalCompletedOrdersDBReadyEvent');
    })
});
/**
 * Event Listener For readyToGetQueuedOrders
 * Retrieves queued orders and sets the window.OrderQue global array
 *
 */
myEmitter.on('readyToGetQueuedOrders', () => {
    db.collection(queuedOrdersDbName).get()
        .then(orderQue => {
            window.OrderQue = orderQue;
            myEmitter.emit('finishedGettingOrderQue');
        })
});
/**
 * Fires the window.getNewOrder(orderID) function;
 * @param orderID
 */
window.getOrder = (orderID) => {
    window.getNewOrder(orderID);
}
/**
 * Sync Orders with the NVLSS Server
 * Fetches new orders and  puts the response in the local orders collection.
 * @param  {String} driverID The user ID of the driver
 */
window.syncOrders = (driverID) => {
    // Check that the user data is populated and the DriverID Exists
    // If driverID Exists
    if (isNumber(driverID)) {
        // Fetch new orders from NLN Server
        fetch(getNewOrdersAPIUrl + driverID)
            .then((response) => {
                // wait for the response
                return response.json();
            })
            .then((jsonResponse) => {
                // Function call to put new orders in the Local orders collection
                window.putRemoteOrdersInLocalDB(jsonResponse);
            })
            .catch(function (err) {
                // Log Error
                console.log('Fetch Error :-S', err);
            });
    } else {
        // If driverID does not exist refresh the global window.currentUserID from the local user collection
        db.collection(userDbName).get().then(users => {
            window.currentUserID = users[0].id;
            driverID = window.currentUserID;
            // Fetch new orders from NLN Server
            fetch(getNewOrdersAPIUrl + driverID)
                .then((response) => {
                    // wait for the response
                    return response.json();
                })
                .then((jsonResponse) => {
                    // Function call to put new orders in the Local orders collection
                    window.putRemoteOrdersInLocalDB(jsonResponse);
                })
                .catch(function (err) {
                    // Log Error
                    console.log('Fetch Error :-S', err);
                });
        })

    }
}
/**
 * Generate a unique 9 character key
 */
window.getUniqueKey = () => {
    // Math.random should be unique because of its seeding algorithm.
    // Convert it to base 36 (numbers + letters), and grab the first 9 characters
    // after the decimal.
    return '_' + Math.random().toString(36).substr(2, 9);
};
/**
 * Get the current user document and sets the window.currentUserID & window.currentUserName global variable
 */
window.getUserData = () => {
    db.collection(userDbName).get().then(users => {
        window.currentUserID = users[0].id;
        window.currentUserName = users[0].name;
    })
}
/**
 * Get the current user document and sets the window.currentUserID and window.currentUserName global variables
 * In addition sets the users name on the side menu to the menuUserName element
 */
window.populateUserTableLocal = () => {
    db.collection(userDbName).get().then(users => {
        window.currentUserID = users[0].id;
        window.currentUserName = users[0].name;
        $('#menuUserName').append("<br>" + window.currentUserName);
    })

}
/**
 * Fetches the current user data from the NVLSS server and sets the window.currentUserID and window.currentUserName global variable
 * In addition sets the users name on the side menu to the menuUserName element
 * @event window#UserDataGathered
 */
window.populateUserTable = () => {
    // Fetch User Data From NVLSS Server
    fetch(getNewUserAPIUrl)
        // Wait for Response
        .then((response) => {
            return response.json();
        })
        // Process User Data Response
        .then((jsonResponse) => {
            // Insert fetched user data in to the local user collection
            db.collection(userDbName).add(jsonResponse, String(jsonResponse.id));
            window.currentUserName = jsonResponse.name;
            window.currentUserID = jsonResponse.id;
            $('#menuUserName').append("<br>" + window.currentUserName);
            setTimeout(() => {
                myEmitter.emit('UserDataGathered');
            }, readDelay);
        })
        .catch(function (err) {
            console.log('Fetch Error :-S', err);
        });
}
/**
 * Fetches the application settings from the NVLSS server.
 * Additionally, populates the local settings collection with the response.
 */
window.populateSettingsTable = () => {
    fetch(getSettingsAPIUrl)
        .then((response) => {
            return response.json();
        })
        .then((jsonResponse) => {
            let settingsObjects = JSON.parse(JSON.stringify(jsonResponse));

            for (var i = 0; i < settingsObjects.length; i++) {
                db.collection(settingsDbName).add(settingsObjects[i], settingsObjects[i]['key']);
            }
        })
        .catch(function (err) {
            console.log('Fetch Error :-S', err);
        });
}
/**
 * Fetches the users last 20 completed orders
 * Additionally, populates the local completedOrders collection with the response.
 * @param  {String} driverID The user ID of the driver
 */
window.getCompletedOrders = (driverID) => {
    if (isNumber(driverID)) {
        fetch(getCompletedOrdersAPIUrl + driverID)
            .then((response) => {
                return response.json();
            })
            .then((jsonResponse) => {
                window.putCompletedOrdersInLocalDB(jsonResponse);
            })
            .catch(function (err) {
                console.log('Fetch Error :-S', err);
            });
    } else {
        db.collection(userDbName).get().then(users => {
            window.currentUserID = users[0].id;
            fetch(getCompletedOrdersAPIUrl + driverID)
                .then((response) => {
                    return response.json();
                })
                .then((jsonResponse) => {
                    window.putCompletedOrdersInLocalDB(jsonResponse);
                    window.populateUserTable();
                })
                .catch(function (err) {
                    console.log('Fetch Error :-S', err);
                });
        })
    }
}
/**
 * Puts an image object into the newPhotosDbName collection
 * Also Adds It To The capturedPhotos DB  5/2/2023
 * @param  {object} imageObject The image object
 * @return {String} The local documents key stored in the newPhotosDbName collection
 */
window.addImageToLocalDB = (imageObject) => {
    imageObject[0]['key'] = window.getUniqueKey();
    console.log('Added To Captured Photos');
    db.collection(capturedPhotosDbName).doc('nlnorder').add(imageObject[0]);
    db.collection(newPhotosDbName).doc('nlnorder').add(imageObject[0]);
    return imageObject[0]['key'];
}
/**
 * Deletes an image document by Key from the newPhotosDbName collection
 * @param  {string} imageKey The Key of the image document
 */
window.deleteImageFromLocalDB = function (imageKey) {
    db.collection(newPhotosDbName).doc({key: imageKey}).delete();
}
/**
 * Puts completed orders array into completedOrdersDbName collection
 * @param  {object} data The orders object array
 */
window.putCompletedOrdersInLocalDB = (data) => {
    let completedOrderObjects = JSON.parse(JSON.stringify(data));

    for (let i = 0; i < completedOrderObjects.length; i++) {
        let postProperty = true;
        let orderClass = "none";
        let daysTillDue = completedOrderObjects[i]['due_in_days'];
        if (daysTillDue === 0) {
            orderClass = "dueToday";
        } else if (daysTillDue < 0) {
            orderClass = "pastDue";
        }
        completedOrderObjects[i]['orderClass'] = orderClass;
        completedOrderObjects[i]['_key'] = String(completedOrderObjects[i].id);
        let options = completedOrderObjects[i]['json_form_options'];
        if (options.hasOwnProperty('Post')) {
            if (options.Post == false) {
                postProperty = false
            }
        }
        completedOrderObjects[i]['postProperty'] = postProperty;
        if (i + 1 === completedOrderObjects.length) {
            setTimeout(() => {
                db.collection(completedOrdersDbName)
                    .set(completedOrderObjects, {keys: true})
                    .then(response => {
                        myEmitter.emit('finishedPopulatingCompletedOrdersDB');
                    })
                    .catch(error => {
                        console.log('There was an error, do something else.')
                    })
            }, readDelay);
            $('#wait').fadeOut();
        }
    }
}
/**
 * Updates order in indexedDB
 * @param  {object} orderData The order object array
 */
window.updateOrderInLocalDB = (orderData) => {

    console.log('Updating Order Data');

    db.collection(ordersDbName)
        .doc({id: orderData.id})
        .update(orderData)
        .then(response => {
            db.collection(ordersDbName).doc({id: orderData.id}).get().then(document => {
                window.CurrentOrder = document;
                myEmitter.emit('orderSavedToLocalDbSuccess');
            })
        })
        .catch(error => {
            myEmitter.emit('orderSavedToLocalDbFailure');
            console.log('There was an error, do something else.')
        })
}
/**
 * Event Listener For Queued Order Saved To Remote DB
 *
 */
myEmitter.on('BulkOrderSavedToRemoteServerFromQue', () => {
    db.collection(queuedOrdersDbName).get().then(orderObjects => {
        for (let i = 0; i < orderObjects.length; i++) {
            let id = orderObjects[i]['id'];
            // console.log("moving from Que To Completed Display, ID: " + id);
            window.lastOrderToMoveToCompletedFromQue = id;
            window.moveOrderFromQueToCompletedByID(id, orderObjects[i]);
            myEmitter.emit('MoveLastCompletedOrderToCompletedTab');
            if (i + 1 === orderObjects.length || orderObjects.length === 0) {
                setTimeout(() => {
                    myEmitter.emit('bulkOrdersAddedToCompletedLocalBD');
                }, readDelay);
                //  console.log('Move Orders Around');
                $('#wait').fadeOut(10);
            }
        }
    })
    myEmitter.emit('syncListTotals');
});
/**
 * Moves Order From Local BD Que To The Completed DB By Order ID - Order Object Required
 * @param orderID
 * @param orderObject
 */
window.moveOrderFromQueToCompletedByID = (orderID, orderObject) => {
    db.collection(completedOrdersDbName).add(orderObject, String(orderID))
        .then(response => {
            db.collection(queuedOrdersDbName)
                .doc({id: orderID})
                .delete()
                .then(response => {
                    myEmitter.emit('syncListTotals');
                })
                .catch(error => {
                    console.log('There was an error, do something else.')
                })
        })
        .catch(error => {
            //  myEmitter.emit('orderSavedToLocalDbFailure');
            console.log('There was an error, do something else.')
        })
}
/**
 * Moves Order From Local BD Que To The Completed DB By Order ID Only
 * @param orderID
 */
window.moveOrderFromQueToCompleted = (orderID) => {
    db.collection(completedOrdersDbName).add(window.CurrentOrder, String(window.CurrentOrder.id))
        .then(response => {
            myEmitter.emit('orderAddedToCompletedLocalBD');
            db.collection(queuedOrdersDbName)
                .doc({id: window.CurrentOrder.id})
                .delete()
                .then(response => {
                    myEmitter.emit('syncListTotals');
                    //  console.log('Delete successful, now do something.')
                })
                .catch(error => {
                    console.log('There was an error, do something else.')
                })
        })
        .catch(error => {
            //  myEmitter.emit('orderSavedToLocalDbFailure');
            console.log('There was an error, do something else.')
        })
}

window.moveOrderFromNewToCompleted = (orderID) => {
    db.collection(completedOrdersDbName).add(window.CurrentOrder, String(window.CurrentOrder.id))
        .then(response => {
            myEmitter.emit('orderAddedToCompletedLocalBD');
            db.collection(ordersDbName).doc(String(window.CurrentOrder.id)).delete()
                .then(response => {
                    myEmitter.emit('syncListTotals');
                })
                .catch(error => {
                    console.log('There was an error Deleting From New Orders, do something else.')
                })
        })

        .catch(error => {
            //  myEmitter.emit('orderSavedToLocalDbFailure');
            console.log('There was an error Adding To Completed Orders, do something else.')
        })
}

window.moveOrderFromNewToQue = (orderID) => {
    db.collection(queuedOrdersDbName).add(window.CurrentOrder, String(window.CurrentOrder.id))
        .then(response => {
            myEmitter.emit('orderAddedToQueuedLocalBD');
            db.collection(ordersDbName)
                .doc({id: window.CurrentOrder.id})
                .delete()
                .then(response => {
                    myEmitter.emit('orderMovedToQue');
                    myEmitter.emit('syncListTotals');
                    //console.log('Delete successful, now do something.')
                })
                .catch(error => {
                    console.log('There was an error, do something else.')
                })
        })
        .catch(error => {
            //  myEmitter.emit('orderSavedToLocalDbFailure');
            console.log('There was an error, do something else.')
        })


}

window.displayQueuedOrder = (order) => {
    let orderClass = order['orderClass'];
    let daysTillDue = order['due_in_days'];
    let options = order['json_form_options'];
    let postProperty = order['postProperty'];
    let orderCardHtml = "";
    if (postProperty === true) {
        orderCardHtml = `<div id='divOrderList-${order['id']}' class="card-option" data-distance="">
                                <div style="background-color: #e91e53;width:100%">
                                <div class="orderType">${order['description']}</div>
                                <div class="address">
                                <a href="https://maps.google.com/?daddr=${order['address']}">
                                <img class="map" src="../images/googlemaps.png"></a>
                                <span style="color: #ffcc00"  class="editLink" href="#" >${order['address']}</span>
                                <div class="distance">
                                    <div id="${order['id']}" style="position: relative;float: right;right:0;">XX mi</div>
                                </div>
                                </div>
                                <div class='detailsBox'>
                                    <div class="zipcode">ID: ${order['id']}</div>
                                    <div class="zipcode ${orderClass}" style="width:60%">DUE: ${daysTillDue} </div>
                                    <div class="zipcode" >ZIP: ${order['zip']}</div>
                                    <div class="zipcode" style="width:60%!important">CITY: ${order['City']}</div>
                                </div></div></div>`;
        $('#ordersWrapper3').append(orderCardHtml);
    }
}

window.processQueuedOrder = () => {
    $('#wait').fadeIn(10);
    // console.log('Processing Order Queue');
    db.collection(queuedOrdersDbName).get()
        .then(ordersQue => {
            fetch(updateRemoteBulkOrdersAPIUrl, {
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    "X-Requested-With": "XMLHttpRequest",
                    'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                }, method: "post", credentials: "same-origin", body: JSON.stringify(ordersQue)
            })
                .then(function (res) {
                    if (res.status === 200) {
                        $('#wait').fadeOut(10);
                        myEmitter.emit('BulkOrderSavedToRemoteServerFromQue');
                    } else {
                        myEmitter.emit('ErrorUpdatingRemoteDB');
                    }
                })
                .catch(function (res) {
                    $('#wait').fadeOut(10);
                    myEmitter.emit('ErrorUpdatingRemoteDB');
                })
        })
}

window.setQueuedPhotoIndicator = () => {
    // console.log('Checking Photos');
    db.collection(newPhotosDbName).get().then(document => {
        // console.log(document);
        if (document.length === 0) {
            $('#queuedPhotoIndicator').fadeOut(20);
        } else {
            $('#queuedPhotoIndicator').fadeIn(20);
        }
    })
}

window.updateOrderOnNLNServerFromQue = (orderData) => {
    // console.log("Fetching Order")
    // console.log(orderData);
    if (orderData === undefined) {
        return false;
    }
    db.collection(queuedOrdersDbName).doc({id: +orderData.id}).get().then(document => {
        window.CurrentOrder = document;
        fetch(updateRemoteOrderAPIUrl, {
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "X-Requested-With": "XMLHttpRequest",
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            }, method: "post", credentials: "same-origin", body: JSON.stringify(document)
        })
            .then(function (res) {
                if (res.status === 200) {
                    myEmitter.emit('OrderSavedToRemoteServerFromQue');
                } else {
                    myEmitter.emit('ErrorUpdatingRemoteDB');
                }
            })
            .catch(function (res) {
                myEmitter.emit('ErrorUpdatingRemoteDB');
            })

        setTimeout(() => {
            myEmitter.emit('finishedGettingLocalOrder');
        }, 500);
    })
}

window.updateOrderOnNLNServer = (orderData) => {
    db.collection(ordersDbName).doc({id: +orderData.id}).get().then(document => {
        window.CurrentOrder = document;
        fetch(updateRemoteOrderAPIUrl, {
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "X-Requested-With": "XMLHttpRequest",
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            }, method: "post", credentials: "same-origin", body: JSON.stringify(document)
        })
            .then(function (res) {
                if (res.status === 200) {
                    myEmitter.emit('OrderSavedToRemoteServer');
                } else {
                    myEmitter.emit('ErrorUpdatingRemoteDB');
                }
            })
            .catch(function (res) {
                myEmitter.emit('ErrorUpdatingRemoteDB');
            })

        setTimeout(() => {
            myEmitter.emit('finishedGettingLocalOrder');
        }, 500);
    })
    // console.log(orderData.id);
}

window.isOrderInQue = function (orderID, order) {
    db.collection(queuedOrdersDbName).doc({id: +orderID}).get().then(document => {
        if (document) {
            window.displayQueuedOrder(order);
            // console.log('Order Found In Queue Deleted From New Orders');
        } else {
            let postProperty = true;
            db.collection(ordersDbName).add(order, String(orderID));
        }
    }).catch(function (res) {
        return false;
    })
}

window.putRemoteOrdersInLocalDB = async (data) => {

    db.collection(ordersDbName).set([]).then(response => {
        let orderObjects = JSON.parse(JSON.stringify(data));
        if (orderObjects.length === 0) {
            $('#wait').fadeOut(100);
            myEmitter.emit('finishedPopulatingLocalOrdersDB');
        } else {
            for (let i = 0; i < orderObjects.length; i++) {
                let postProperty = true;
                let orderClass = "none";
                let daysTillDue = orderObjects[i]['due_in_days'];
                if (daysTillDue === 0) {
                    orderClass = "dueToday";
                } else if (daysTillDue < 0) {
                    orderClass = "pastDue";
                }
                orderObjects[i]['orderClass'] = orderClass;
                let options = orderObjects[i]['json_form_options'];
                options = JSON.parse(options);
                if (options.hasOwnProperty('Post')) {
                    if (options.Post == false) {
                        postProperty = false
                    }
                }
                orderObjects[i]['postProperty'] = postProperty;
                if (postProperty === true) {
                    window.isOrderInQue(orderObjects[i].id, orderObjects[i]);
                }
                if (i + 1 === orderObjects.length || orderObjects.length === 0) {
                    setTimeout(() => {
                        myEmitter.emit('finishedPopulatingLocalOrdersDB');
                    }, readDelay * 2);
                    $('#wait').fadeOut(10);
                }
            }
        }
        // console.log('Add successful, now do something.')
    })
        .catch(error => {
            console.log('There was an error, do something else.')
        })

}

window.getNewOrdersLocal = () => {
    db.collection(ordersDbName).get()
        .then(orders => {
            return (window.orders);
        })
}

window.clearImagesFromNewImages = () => {
    db.collection(newPhotosDbName).delete();
}

window.removeImagesFromNewImages = (removeImageArray) => {
    const images = eval(removeImageArray);
    let iLen = images.length;

    for (let i = 0; i < iLen; i++) {
        window.deleteImageFromLocalDB(images[i]);
        console.log("Image: " + images[i] + "Was Deleted");
    }
}

window.sendCapturedPhoto = function (key) {
  //  console.log(`KEY:${key}`)

    db.collection(capturedPhotosDbName).doc({key:key}).get()
        .then(photos => {
           // console.log(JSON.stringify(photos));
           if (photos) {

               console.log('Fetch Upload')

               fetch(getCapturedPhotoUploadUrl, {
                   headers: {
                       "Content-Type": "application/json",
                       "Accept": "application/json",
                       "X-Requested-With": "XMLHttpRequest",
                       'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                   }, processData: false, method: "post", credentials: "same-origin", body: JSON.stringify(photos)
               }).then(response => response.text())
                   .then(data => {

                       console.log(data);
                       //  window.removeImagesFromNewImages(data);
                       //  $('#queuedPhotoIndicator').fadeOut();
                        myEmitter.emit('capturedPhotoUploaded');
                   })
                   .catch(function (res) {
                       // $('#queuedPhotoIndicator').fadeIn();
                       // myEmitter.emit('ErrorUploadingImages');
                   })
           }

        //    }
            // setTimeout(() => {myEmitter.emit('LocalPhotosUploadedToNVLSS');},readDelay);
        })
}

window.sendQueuedPhotos = function () {
    db.collection(newPhotosDbName).get()
        .then(photos => {
            if (photos.length > 0) {
                fetch(getUploadBulkImagesUrl, {
                    headers: {
                        "Content-Type": "application/json",
                        "Accept": "application/json",
                        "X-Requested-With": "XMLHttpRequest",
                        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                    }, method: "post", credentials: "same-origin", body: JSON.stringify(photos)
                }).then(response => response.text())
                    .then(data => {
                        window.removeImagesFromNewImages(data);
                        $('#queuedPhotoIndicator').fadeOut();
                        myEmitter.emit('ClearNewPhotosDBClearRequested');
                    })
                    .catch(function (res) {
                        $('#queuedPhotoIndicator').fadeIn();
                        // myEmitter.emit('ErrorUploadingImages');
                    })
            }
            // setTimeout(() => {myEmitter.emit('LocalPhotosUploadedToNVLSS');},readDelay);
        })
}

window.clearNewPhotosCollection = () => {
    db.collection(newPhotosDbName)
        .delete()
        .then(response => {
            myEmitter.emit('NewPhotosDBCleared');
            console.log('New Photos Collection deleted.')
        })
        .catch(error => {
            myEmitter.emit('NewPhotosDBCouldNotBeCleared');
            console.log('There was an error New Photos Couldn\'t Be Deleted')
        })
}

window.clearQueuedOrdersLocalDB = () => {
    db.collection(queuedOrdersDbName)
        .delete()
        .then(response => {
            myEmitter.emit('LocalQueuedOrdersDBCleared');
            console.log('LocalQueuedOrdersDBCleared')
        })
        .catch(error => {
            myEmitter.emit('LocalQueuedOrdersDBNotCleared');
            console.log('LocalQueuedOrdersDBNotCleared')
        })
}

window.getNewPhotos = () => {
    db.collection(newPhotosDbName).get()
        .then(photos => {
            window.photos = photos;
            setTimeout(() => {
                myEmitter.emit('LocalPhotosFetchedFromClientLocalBD');
            }, readDelay);
        })
}

function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}



window.getCapturedPhotos = () => {
    console.log('Getting Captured Images');
    db.collection(capturedPhotosDbName).get()
        .then(photos => {
            window.capturedPhotos = photos;
            sleep(500);
            setTimeout(() => {
                myEmitter.emit('CapturedPhotosFetchedFromClientLocalBD');
               return photos ;
            }, readDelay);
        })
}

window.deleteLocalPhotosOverThirtyDaysOld = () => {

    const daysToKeepPhotos = 7;
    db.collection(capturedPhotosDbName).get()
        .then(photos => {
            const now = new Date();
            for (let i = 0; i < photos.length; i++) {
               //  console.log(photos[i]);

                let fileName = photos[i]['fileName'].split(".");
                let photoTakenDateTime = +fileName[1];
                const datePhotoTaken = new Date(photoTakenDateTime);
                const dateValues = [
                    datePhotoTaken.getFullYear(),
                    datePhotoTaken.getMonth()+1,
                    datePhotoTaken.getDate(),
                    datePhotoTaken.getHours(),
                    datePhotoTaken.getMinutes(),
                    datePhotoTaken.getSeconds(),
                ];
                //it is pm if hours from 12 onwards
                let  suffix = (dateValues[3] >= 12)? 'PM' : 'AM';

                //only -12 from hours if it is greater than 12 (if not back at mid night)
                let hours = (+dateValues[3] > 12)? +dateValues[3] -12 : +dateValues[3];
                let currentPhotoDate = `${dateValues[1]}-${dateValues[2]}-${dateValues[0]}`;
                const pastTime = new Date(currentPhotoDate);
                const thirtyDaysInMs = daysToKeepPhotos * 24 * 60 * 60 * 1000;

                const timeDiffInMs = now.getTime() - pastTime.getTime();

                if(timeDiffInMs >= thirtyDaysInMs){
                  console.log(currentPhotoDate + "  :  " +  photos[i]['key'] + " SHOULD BE DELETED");

                    db.collection(capturedPhotosDbName)
                        .doc({ fileName: photos[i]['fileName'] })
                        .delete()
                        .then(response => {
                            console.log('Delete Image successful.')
                        })
                        .catch(error => {
                            console.log(error)
                        })
                }

                if(i+1 >= photos.length) {
                    myEmitter.emit('ClearedCapturedImagesOver30');
                }
            }
        });

}

window.getNewOrder = function (OrderID) {
    db.collection(ordersDbName).doc({id: +OrderID}).get().then(document => {
        window.CurrentOrder = document;
        setTimeout(() => {
            myEmitter.emit('finishedGettingLocalOrder');
        }, 300);
    })
}

window.checkConnectivity = () => {
    console.log('Checking Connectivity...');
    fetch(checkConnectivityAPIUrl)
        .then((response) => {
            if (response) {
                console.log('Connectivity Response:' + response);
                return response.json();
            } else {
                myEmitter.emit('finishedFailedSendOrderPreTest');
            }
        })
        .then((jsonResponse) => {
            let connectionResults = JSON.parse(JSON.stringify(jsonResponse));
            if (connectionResults.result === 'success') {
                myEmitter.emit('finishedSuccessfulSendOrderPreTest');
            } else {
                myEmitter.emit('finishedSuccessfulSendOrderPreTest');
                console.log('Adding To Que - Failed Network Check')
                myEmitter.emit('finishedFailedSendOrderPreTest');
            }
        })
        .catch(function (err) {
            myEmitter.emit('finishedSuccessfulSendOrderPreTest');
            myEmitter.emit('finishedFailedSendOrderPreTest');
            console.log('Fetch Error :-S', err);
        });
}

window.initDriverHome = function (DriverID) {window.syncOrders(DriverID);}